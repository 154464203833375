<template>
    <div class="row" style="margin: 50px">
        <div class="col-12">
            <h1>Portals</h1>
        </div>
    </div>
    <div class="row" style="margin: 50px;">
        <div class="col-3">
            <div class="card m-b-sm">
                <div class="card-header">
                    <h5>Customer Portal</h5>
                </div>
                <div class="card-body">
                    <button class="btn btn-primary" @click="customerPortal">Access Customer Portal</button>
                </div>
            </div>
        </div>
        <div v-if="$can('ncs-view')" class="col-3">
            <div class="card m-b-sm">
                <div class="card-header">
                    <h5>NBCS Portal</h5>
                </div>
                <div class="card-body">
                    <button class="btn btn-primary" @click="nbcsPortal">Access NBCS Portal</button>
                </div>
            </div>
        </div>
        <div v-if="$can('dcbl-view')" class="col-3">
            <div class="card m-b-sm">
                <div class="card-header">
                    <h5>DCBL Portal</h5>
                </div>
                <div class="card-body">
                    <button class="btn btn-primary" @click="dcblPortal">Access DCBL Portal</button>
                </div>
            </div>
        </div>
        <div v-if="$can('portals-admin')" class="col-3">
            <div class="card m-b-sm">
                <div class="card-header">
                    <h5>Admin Portal</h5>
                </div>
                <div class="card-body">
                    <button class="btn btn-primary" @click="adminPortal">Access Admin Portal</button>
                </div>
            </div>
        </div>
        <div v-if="$can('claims-process')" class="col-3">
            <div class="card m-b-sm">
                <div class="card-header">
                    <h5>Claims Portal</h5>
                </div>
                <div class="card-body">
                    <button class="btn btn-primary" @click="claimsPortal">Access Claims Portal</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Portals',
    data(){
        return {
            
        }
    },
    mounted(){

        if(!this.$can('ncs-view') && !this.$can('portals-admin') && !this.$can('claims-process')){
            this.customerPortal();
        }

    },
    methods: {
        customerPortal(){
            window.location = 'https://portal.varsanpr.com/login?token=' + this.$store.state.auth.user.token;
        },
        nbcsPortal(){
            window.location = 'https://nbcs.varsanpr.com/login?token=' + this.$store.state.auth.user.token;
        },
        adminPortal(){
            window.location = 'https://admin.varsanpr.com/login?token=' + this.$store.state.auth.user.token;
        },
        claimsPortal(){
            window.location = 'https://claims.varsanpr.com/login?token=' + this.$store.state.auth.user.token;
        },
        dcblPortal(){
            window.location = 'https://dcbl.varsanpr.com/login?token=' + this.$store.state.auth.user.token;
        }
    }
}
</script>